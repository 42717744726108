const MobileMenu = {
  MENU_ID: 'mobile-menu',
  OPEN_BUTTON_ID: 'open-menu',
  CLOSE_BUTTON_ID: 'close-menu',
  OVERLAY_ID: 'menu-overlay',
  HIDDEN_CLASS: 'hidden',
  BODY_NO_SCROLL_CLASS: 'overflow-y-hidden',

  init() {
    const menu = document.getElementById(MobileMenu.MENU_ID);
    const overlay = document.getElementById(MobileMenu.OVERLAY_ID);
    const openButton = document.getElementById(MobileMenu.OPEN_BUTTON_ID);
    const closeButton = document.getElementById(MobileMenu.CLOSE_BUTTON_ID);

    if (!menu || !overlay || !openButton || !closeButton) {
      return;
    }

    openButton.addEventListener('click', () => MobileMenu.openMenu(menu, overlay));
    closeButton.addEventListener('click', () => MobileMenu.closeMenu(menu, overlay));
    overlay.addEventListener('click', () => MobileMenu.closeMenu(menu, overlay));
  },

  openMenu(menu, overlay) {
    menu.classList.remove(MobileMenu.HIDDEN_CLASS);
    overlay.classList.remove(MobileMenu.HIDDEN_CLASS);
    menu.removeAttribute('inert');
    document.body.classList.add(MobileMenu.BODY_NO_SCROLL_CLASS);  // Zablokowanie przewijania
  },

  closeMenu(menu, overlay) {
    menu.classList.add(MobileMenu.HIDDEN_CLASS);
    overlay.classList.add(MobileMenu.HIDDEN_CLASS);
    menu.setAttribute('inert', '');
    document.body.classList.remove(MobileMenu.BODY_NO_SCROLL_CLASS);  // Przywrócenie przewijania
  },
};

module.exports = MobileMenu;
