const CookieConsent = {
  CONSENT_CLASS: 'santv-cookie-consent',
  FORM_CLASS: 'ch-cookie-consent-form',
  BUTTON_CLASS: 'ch-cookie-consent-button',
  CATEGORY_GROUP_CLASS: 'ch-cookie-consent__category-group',
  TOGGLE_DETAILS_CLASS: 'ch-cookie-consent__toggle-details',

  init() {
    const consentElement = document.querySelector(`.${CookieConsent.CONSENT_CLASS}`);
    const consentForm = document.querySelector(`.${CookieConsent.FORM_CLASS}`);
    const consentButtons = document.querySelectorAll(`.${CookieConsent.BUTTON_CLASS}`);
    const categoryDetails = document.querySelector(`.${CookieConsent.CATEGORY_GROUP_CLASS}`);
    const toggleDetails = document.querySelector(`.${CookieConsent.TOGGLE_DETAILS_CLASS}`);
    CookieConsent.deleteAllCookies();


    if (consentElement && consentElement.parentNode.nodeName === 'BODY') {
      document.documentElement.style.overflow = 'hidden';
    }

    if (consentForm) {
      CookieConsent.addFormSubmitListeners(consentForm, consentButtons, consentElement);
    }

    if (categoryDetails && toggleDetails) {
      CookieConsent.addToggleDetailsListener(categoryDetails, toggleDetails);
    }
  },

  adjustBodyMargin(consentElement) {
    const isTop = consentElement.classList.contains(`${CookieConsent.CONSENT_CLASS}--top`);

    if (isTop) {
      document.body.style.marginTop = `${consentElement.offsetHeight}px`;
      consentElement.style.position = 'absolute';
      consentElement.style.top = '0';
      consentElement.style.left = '0';
    } else {
      document.body.style.marginBottom = `${consentElement.offsetHeight}px`;
      consentElement.style.position = 'fixed';
      consentElement.style.bottom = '0';
      consentElement.style.left = '0';
    }
  },

  addFormSubmitListeners(form, buttons, consentElement) {
    buttons.forEach(button => {
      button.addEventListener('click', event => {
        event.preventDefault();

        const formAction = form.action || location.href;
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            consentElement.style.display = 'none';
            const buttonEvent = new CustomEvent('cookie-consent-form-submit-successful', {
              detail: event.target
            });
            document.dispatchEvent(buttonEvent);
            location.reload();
          }
        };

        xhr.open('POST', formAction);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(CookieConsent.serializeForm(form, event.target));

        document.body.style.marginBottom = null;
        document.body.style.marginTop = null;
        document.documentElement.style.overflow = null;

      });
    });
  },

  deleteAllCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const cookieName = cookie.split('=')[0].trim();
      if (cookieName) {
        CookieConsent.deleteCookie(cookieName);
      }
    }
  },

  deleteCookie(name) {
    document.cookie = `${name}=; Max-Age=0; path=/; domain=${location.hostname}`;

    const domainParts = location.hostname.split('.');
    if (domainParts.length > 2) {
      const mainDomain = `.${domainParts.slice(-2).join('.')}`;
      document.cookie = `${name}=; Max-Age=0; path=/; domain=${mainDomain}`;
    }
  },


  addToggleDetailsListener(categoryDetails, toggleDetails) {
    toggleDetails.addEventListener('click', () => {
      const isHidden = categoryDetails.style.display !== 'block';
      categoryDetails.style.display = isHidden ? 'block' : 'none';

      const hideElement = toggleDetails.querySelector(`.${CookieConsent.TOGGLE_DETAILS_CLASS}-hide`);
      const showElement = toggleDetails.querySelector(`.${CookieConsent.TOGGLE_DETAILS_CLASS}-show`);

      if (hideElement && showElement) {
        hideElement.style.display = isHidden ? 'block' : 'none';
        showElement.style.display = isHidden ? 'none' : 'block';
      }
    });
  },

  serializeForm(form, clickedButton) {
    const serialized = Array.from(form.elements).reduce((acc, field) => {
      if ((field.type !== 'checkbox' && field.type !== 'radio' && field.type !== 'button') || field.checked) {
        acc.push(`${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`);
      }
      return acc;
    }, []);

    serialized.push(`${encodeURIComponent(clickedButton.getAttribute('name'))}=`);

    return serialized.join('&');
  },
};

// Polyfill for CustomEvent
if (typeof window.CustomEvent !== 'function') {
  window.CustomEvent = function (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };
  window.CustomEvent.prototype = window.Event.prototype;
}

module.exports = CookieConsent;
