const FlashMessage = {
  FLASH_MESSAGE_SELECTOR: 'flash-message',
  CLOSE_BUTTON_SELECTOR: 'close-button',

  init() {
    FlashMessage.initFlashMessage();
  },
  initFlashMessage() {
    const flashMessages = document.getElementsByClassName(FlashMessage.FLASH_MESSAGE_SELECTOR);

    for (let message of flashMessages) {
      FlashMessage.initCloseButton(message)
      FlashMessage.initTimeRemoval(message)
    }
  },
  initCloseButton(message) {
    const button = message.querySelector(`.${FlashMessage.CLOSE_BUTTON_SELECTOR}`);

    if (button) {
      button.addEventListener('click', () => message.remove());
    }
  },
  initTimeRemoval(message) {
    setTimeout(() => message.remove(), 10000);
  }
}

module.exports = FlashMessage;
